@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dotted-line {
  border-left: 2px dotted lightgrey;
  height: 100px;
}

.wavy-underline {
  position: relative;
}

.wavy-underline::after {
  content: '';
  position: absolute;
  bottom: -3px; /* Adjust this value based on your font size */
  left: 0;
  width: 100%;
  height: 3px; /* Adjust the height for thicker or thinner waves */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><path d="M0 0c5 0 5 5 10 5S20 0 25 0s5 5 10 5 5-5 10-5 5 5 10 5 5-5 10-5" fill="none" stroke="black" stroke-width="1"/></svg>') repeat-x;
  background-size: 10px 3px; /* Adjust the size to change wave frequency and height */
}