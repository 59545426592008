.email-logo {
  background-position: -200px 0; /* Position of the second icon in the sprite */
}

.telegram-logo {
  background-position: -50px 0; /* Position of the second icon in the sprite */
}

.whatsapp-logo {
  background-position: -100px 0; /* Position of the second icon in the sprite */
}

.phone-logo {
  background-position: -75px 0; /* Position of the second icon in the sprite */
}

textarea {
  transition: background-color 0.25s ease; /* Adjust if using a background-color instead of opacity */
}

.white-svg {
  filter: brightness(0) invert(1);
}