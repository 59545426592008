.draggable-container {
  transition: transform 0.5s ease;
}

.dragging {
  transition: none;
}

.arrow {
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.arrow.right {
  transform: rotate(-90deg);
}

.arrow.left {
  transform: rotate(90deg);
}

.arrow.down {
  transform: rotate(0deg);
}

.arrow.up {
  transform: rotate(180deg);
}

.arrow.smaller span {
  animation: animate-shorter 2s infinite !important;
}

.arrow span {
  display: block;
  width: 11px;
  height: 11px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}

@keyframes animate-shorter {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-10px, -10px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(10px, 10px);
  }
}