@keyframes slideLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes slideFromRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideBelow {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes slideFromBelow {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideAbove {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes slideFromAbove {
  from {
    opacity: 0;
    transform: translateY(-70%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-left {
  animation: slideLeft 0.5s forwards;
}

.slide-right {
  animation: slideRight 0.5s forwards;
}

.slide-above {
  animation: slideAbove 0.5s forwards;
}

.slide-below {
  animation: slideBelow 0.5s forwards;
}

.slide-from-left {
  animation: slideFromLeft 0.5s forwards;
}

.slide-from-right {
  animation: slideFromRight 0.5s forwards;
}

.slide-from-above {
  animation: slideFromAbove 0.5s forwards;
}

.slide-from-below {
  animation: slideFromBelow 0.5s forwards;
}